<template align-center class="pa-0">

<v-container class="pa-0 mb-4">


    <v-toolbar flat color="opcion1">
        <v-toolbar-title>Analisis de bitacoras
      
        </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>


         <v-flex  xs4 sm4 md2 lg2  class="mt-2">
              <v-text-field
              class="mt-3"
              prepend-icon="today"
              v-model="fecha_inicio"
              :disabled="true"
            ></v-text-field>
         </v-flex>

        <v-flex  xs4 sm4 md2 lg2  class="mt-2">
              <v-text-field
                class="mt-3"
                prepend-icon="today"
                v-model="fecha_fin"
                :disabled="true"
              ></v-text-field>
              </v-flex>



         <v-btn color="indigo" dark class=" ml-9 mt-1" @click="dialogInicio=true"><v-icon>today</v-icon></v-btn>


        <v-btn color="success" @click="listar()" class="mt-1 ml-8"> 
                  <v-icon dark>sync</v-icon>
                </v-btn>


    </v-toolbar>


     <v-row class="mt-1 mx-1">
        <v-col cols="6" sm="12" md="6" lg="6" xl="6">
                <v-card>
                  
                  <v-card-title>Tipos de Eventos realizados</v-card-title>

                    <v-card-text>
                    <apexchart width="480"  height="200"  type="bar" :options="optionsEventos" :series="seriesEventos"></apexchart>  


                    
                     <v-divider class="mt-2"></v-divider>
                    <v-icon  class="mr-2" small>home_repair_service</v-icon>
                    <span>Realizados:
                      <v-chip class="ma-2"  dark color="red"> {{ cantidadTotalEventos }}</v-chip>
                    </span>

                     <v-icon  class="mr-2" small>do_not_disturb_alt</v-icon>
                    <span>Cancelados:
                      <v-chip class="ma-2"  dark color="indigo"> {{ CantidadtotalCancelaciones }}</v-chip>
                    </span>


                    <v-icon  class="mr-2" small>timer</v-icon>
                    <span>Hrs.Esperadas:
                      <v-chip class="ma-2"  dark color="success"> {{ CantidadtotalEsperado }}</v-chip>
                    </span>


                    </v-card-text>
                </v-card>
        </v-col>


         <v-col cols="6" sm="12" md="6" lg="6" xl="6">
                <v-card>
                  
                  <v-card-title>Etiquetas Revisiones</v-card-title>

                    <v-card-text>
                    <apexchart width="480"  height="220"  type="bar" :options="optionsEtiquetas" :series="seriesEtiquetas"></apexchart>  

                      <v-divider class="mt-2"></v-divider>
                    <v-icon  class="mr-2" small>support_agent</v-icon>
                    <span>Claro:
                      <v-chip class="ma-2"  dark color="red"> {{ cantidadTotalClaro }}</v-chip>
                    </span>


                     <v-icon  class="mr-2" small>support_agent</v-icon>
                    <span>Tigo:
                      <v-chip class="ma-2"  dark color="indigo"> {{ CantidadtotalTigo }}</v-chip>
                    </span>



                    </v-card-text>
                </v-card>
         </v-col>


          <v-col cols="6" sm="12" md="6" lg="6" xl="6">
                <v-card>
                  
                  <v-card-title>Eventos por tecnico</v-card-title>

                    <v-card-text>
                    <apexchart width="480"  height="200"  type="bar" :options="optionsEventosTecnico" :series="seriesEventosTecnico"></apexchart>  


                    </v-card-text>
                </v-card>
          </v-col>

          <v-col cols="6" sm="12" md="6" lg="6" xl="6">
                <v-card>
                  
                  <v-card-title>Equipos revisados</v-card-title>

                    <v-card-text>
                    <apexchart width="480"  height="200"  type="bar" :options="optionsEquipos" :series="seriesEquipos"></apexchart>  


                    </v-card-text>
                </v-card>
        </v-col>





        <v-col cols="6" sm="12" md="12" lg="12" xl="12">

          <v-card>

                <v-data-table
                :headers="headers"
                :search="search"
                :items="itemsMonetizar"
                sort-by="fecha"
                class="elevation-1 d-block"
              >


            <template v-slot:top>
            <v-toolbar  flat color="opcion1">
              <v-toolbar-title>Bitacora Trabajo Monetizada</v-toolbar-title>
              <v-divider class="mx-4"  inset vertical></v-divider>

         <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>





    
    
          <vue-excel-xlsx v-show="data_excel.length >0"
              class="ml-5"
              :data="data_excel"
              :columns="column_excel"
              :filename="'Historial'"
              :sheetname="'hoja1'">
              <v-btn color="info">
                  <v-icon dark>get_app</v-icon>
              </v-btn>
            
              </vue-excel-xlsx>

       
              <v-spacer></v-spacer>



    

      </v-toolbar>
    </template>


       <template v-slot:[`item.fecha`]="{ item }"> 
       <span>{{ new Date(item.fecha).toISOString().substr(0, 10) }} </span>
      </template>


       <template v-slot:[`item.id_bitacora_cliente`]="{ item }"> 
       <span>{{item.id_bitacora_cliente.nombre_cliente}}</span>
      </template>

       <template v-slot:[`item.id_tipo_trabajo`]="{ item }"> 
       <span>{{item.id_tipo_trabajo.nombre_trabajo}}</span>
       </template>

       <template v-slot:[`item.observacion_operador`]="{ item }">
       <span class="text-caption">{{item.observacion_operador}}</span>
       </template>

        <template v-slot:[`item.descripcion_trabajo`]="{ item }">
        <span class="text-caption">{{item.descripcion_trabajo}}</span>
        </template>

      

              
              
              
              
              
              
              </v-data-table>

          </v-card>

        </v-col>
        



         <v-dialog v-model="dialogInicio" persistent max-width="280">
        <v-card>
          <v-toolbar flat color="red" class="text-center">
          <v-toolbar-title class="white--text  text-center"><strong>Analisis por fechas</strong></v-toolbar-title>
        </v-toolbar>

          <v-card-text>



            <v-flex>
        
            

                      <v-menu
                        ref="menuFecha1"
                        v-model="menuFecha1"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                    
                        offset-y
                        min-width="290px"
                      >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fecha_inicio"
                                label="Fecha inicio"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_inicio" locale="es"
                            no-title  @input="menuFecha1 = false" scrollable>
                            </v-date-picker>
                      </v-menu>
                </v-flex>

            <v-flex>
                  <v-menu
                    ref="menuFecha2"
                    v-model="menuFecha2"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                
                    offset-y
                    min-width="290px"
                  >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fecha_fin"
                            label="Fecha fin"
                            prepend-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="fecha_fin" locale="es"
                        no-title  @input="menuFecha2 = false" scrollable>
                        </v-date-picker>
                  </v-menu>

                </v-flex>

            
                  <v-flex justify-center d-flex>
                    
                        <v-btn color="success" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                      <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

                </v-flex>
    
          </v-card-text>
        
        </v-card>
        </v-dialog>


     </v-row>

</v-container>
      
</template>
    
<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";

import VueApexCharts from 'vue-apexcharts';


export default {
      components:{
     apexchart: VueApexCharts
  },
  data(){
         return{
             ArregloGlobalBitacoras:[],
             cantidadTotalEventos:0,
             CantidadtotalCancelaciones:0,
             CantidadtotalEsperado:0,


             cantidadTotalClaro:0,
             CantidadtotalTigo:0,

             optionsEventos:{},
             seriesEventos:[],


               //opciones del dialogo de fechas
                menu: false,
                dialogInicio:true,
                menuFecha1:false,
                fecha_inicio:new Date().toISOString().substr(0, 10),
                menuFecha2:false,
                fecha_fin:new Date().toISOString().substr(0, 10),
                date: new Date().toISOString().substr(0, 10),



                optionsEtiquetas:{},
                seriesEtiquetas:[],


                optionsEventosTecnico:{},
                seriesEventosTecnico:[],


                optionsEquipos:{},
                seriesEquipos:[],



                //tabla de monetizacion
                headers:[
                    { text: 'Fecha', value: 'fecha' },
                    { text: 'Cliente', value: 'id_bitacora_cliente' },
                    { text: 'Tipo Trabajo', value: 'id_tipo_trabajo' },
                    { text: 'Estatus', value: 'status' },
                    { text: 'Id Avl', value: 'id_avl' },
                    { text: 'Placa', value: 'placa' },
                     { text: 'Descripcion trabajo', value: 'descripcion_trabajo' },
                    { text: 'Observacion Operador', value: 'observacion_operador' }
                ],
                search:'',
                itemsMonetizar:[],

                data_excel:[],
                  column_excel:[ 

                        {label: "fecha",  field: "fecha"},
                        {label: "hora_establecida",  field: "hora_establecida"},
                        {label: "hora_llegada_tecnico", field: "hora_llegada_tecnico"},
                        {label: "hora_inicio", field: "hora_inicio"},
                        {label: "hora_fin", field: "hora_fin"},
                        {label: "tiempo_espera", field:"tiempo_espera"},

                        {label: "cliente", field: "cliente"},                                     //id
                        {label: "contacto", field:"contacto"},

                        {label: "direccion",  field: "direccion"},

                        {label: "Tipo_trabajo",  field: "tipo_trabajo"},                      //id
                        {label: "descripcion_trabajo",  field: "descripcion_trabajo"},

                        {label: "status", field: "status"},
                        {label: "etiqueta", field: "etiqueta"},


                        {label: "placa_anterior", field: "placa_anterior"},
                        {label: "serie_anterior", field: "serie_anterior"},
                        {label: "imei_anterior", field: "imei_anterior"},
                        

                        {label: "Tipo_Equipo", field: "tipo_equipo"},                         //id
                        {label: "id_avl", field: "id_avl"},

                        {label: "nombre", field: "nombre"},
                        {label: "placa", field: "placa"},
                        {label: "flota", field: "flota"},

                        {label: "Tecnicos", field: "tecnicos"},                                //id
                        {label: "observacion_operador", field: "observacion_operador"},

                        {label: "estilo", field: "estilo"},
                        {label: "marca", field: "marca"},
                        {label: "modelo", field: "modelo"},
                        {label: "color", field: "color"},
                        {label: "anio", field: "anio"},
                        {label: "chasis", field: "chasis"},
                        {label: "serie", field: "serie"},
                        {label: "imei", field: "imei"},
                        {label: "simcard", field: "simcard"},
                        {label: "proveedor_simcard", field: "proveedor_simcard"},

                        {label: "accesorios_instalados", field: "accesorios_instalados"},          //id 
                        {label: "ubicacion_equipo", field: "ubicacion_equipo"},
                        {label: "observacion_tecnico", field: "observacion_tecnico"},
                        {label: "procesado_avl", field: "procesado_avl"},
                        {label: "creado", field: "creado"},
                        {label: "editado", field: "editado"}


                      ],

                      ArrayAccesorios:[],





         }
  },

   created () {

    this.$root.$refs.M.detenerTimer();

   },

    watch: {

    },

    computed: {

    ...mapState(['usuario', 'token']),
    },

    methods:{

      ...mapMutations(['mostrarLoading','ocultarLoading']),

       listar(){


        this.optionsEventos={};
        this.seriesEventos=[];

        this.optionsEtiquetas={};
        this.seriesEtiquetas=[];

        this.optionsEquipos={};
        this.seriesEquipos=[];


         this.cantidadTotalEventos=0;
         this.CantidadtotalCancelaciones=0;

         this.CantidadtotalEsperado=0;


         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

          me.itemsBitacora=[];
          me.ArregloGlobalBitacoras=[];
          axios.post('bitacoras',
                               { 
                                'desde':new Date(this.fecha_inicio).toISOString().substr(0, 10),
                                'hasta':new Date(this.fecha_fin).toISOString().substr(0, 10) 
                              },
             config
          ).then(function(response){
             //console.log(response);
            me.ArregloGlobalBitacoras=response.data;

            me.cantidadTotalEventos=me.ArregloGlobalBitacoras.length;

            //extraemos los tipos de eventos realizados
            let tipo_trabajos=[];

            //extraemos causales de revision
            let tipo_etiquetas=[];


            //extraemos los equipos mas revisados
            let tipo_equipo=[];



               me.ArregloGlobalBitacoras.map(function(x){

                      //clculamos tiempo esperado

                      if(x.tiempo_espera >0){
                                me.CantidadtotalEsperado=parseInt(x.tiempo_espera)+parseInt(me.CantidadtotalEsperado);
                      }

                     

                  
                      //------calculamos los datos para eventos en graficas------------------

                      let encontrado=false;

                      tipo_trabajos.map(function(z){
                          if(x.id_tipo_trabajo.nombre_trabajo==z.nombre_trabajo){
                              encontrado=true;
                              z.cantidad=parseInt(z.cantidad)+1;
                          }

                      });

                      if(encontrado==false){
                            tipo_trabajos.push({'nombre_trabajo':x.id_tipo_trabajo.nombre_trabajo,'cantidad':1});
                      }


                      if(x.status=='Cancelado'){
                        me.CantidadtotalCancelaciones++;
                      }


                   //------calculamos los datos para etiquetas en graficas------------------

                    let tipo_revision=false;
                    let StringRevision='Revision';
                    let nombre_trabajo=x.id_tipo_trabajo.nombre_trabajo;
                    let posicionRev = nombre_trabajo.indexOf(StringRevision);
                    if (posicionRev !== -1){
                        tipo_revision=true;
                    }


                    
                    if(tipo_revision==true){  


                        let etiqueta_encontrada=false;

                         tipo_etiquetas.map(function(y){
                            if(x.etiqueta==y.etiqueta){
                                etiqueta_encontrada=true;
                                y.cantidad=parseInt(y.cantidad)+1;
                            }
                        });

                         if((etiqueta_encontrada==false)&&(x.etiqueta!='')){
                            tipo_etiquetas.push({'etiqueta':x.etiqueta,'cantidad':1});
                      }


                    //----------calculamos equipos mas revisados------------------------------

                            let equipo_encontrado=false;

                            tipo_equipo.map(function(y){

                              if(x.id_tipo_equipo){
                                if(x.id_tipo_equipo.nombre_equipo==y.nombre_equipo){
                                  equipo_encontrado=true;
                                   y.cantidad=parseInt(y.cantidad)+1;
                                }
                              }

                            });


                              if((equipo_encontrado==false)&&(x.id_tipo_equipo)){
                                   tipo_equipo.push({'nombre_equipo':x.id_tipo_equipo.nombre_equipo,'cantidad':1});
                               }

               }





               });

                let nombre_eventos=[];
                let array_datos_eventos=[];

                tipo_trabajos.map(function(z){
                        nombre_eventos.push(z.nombre_trabajo);
                        array_datos_eventos.push(z.cantidad);

                });

                 me.seriesEventos=[{
                                      name: 'Evento',
                                      data: array_datos_eventos
                                  }];



                                  
                me.optionsEventos={
                                     chart:{
                                        height: 300,
                                        type: 'bar',
                                      },
                                      plotOptions: {
                                        bar: {
                                          horizontal: false,
                                          columnWidth: '55%'
                                        },
                                      },
                                      
                                       title: {
                                        text: ''
                                      },
                                        subtitle: {
                                        text: '',
                                        align: 'center',
                                    },

                                      dataLabels: {
                                        enabled: true
                                      },

                                      stroke: {
                                        show: true,
                                        width: 2,
                                        colors: ['transparent']
                                      },
                                      xaxis: {
                                        categories:  nombre_eventos,
                                      },

                                      
                                     yaxis:[],
                                      fill: {
                                            opacity: 1
                                          }    


                                   };


                //grafica de causal de revisiones


                let nombre_etiquetas=[];
                let array_datos_etiquetas=[];

                tipo_etiquetas.map(function(b){

                        let texto=b.etiqueta;
                        texto= texto.replace(/_/g, " "); //reemplza los guiones


                        nombre_etiquetas.push(texto);
                        array_datos_etiquetas.push(b.cantidad);

                });


                me.seriesEtiquetas=[{
                                name: 'Etiqueta',
                                data: array_datos_etiquetas
                            }];


                me.optionsEtiquetas={
                                    chart: {
                                      type: 'bar',
                                      height: 320
                                    },
                                     plotOptions: {
                                    bar: {
                                      borderRadius: 4,
                                      horizontal: true,
                                    }
                                  },
                                  dataLabels: {
                                    enabled: true
                                  },
                                  xaxis: {
                                          categories:nombre_etiquetas
                                          }
                 };


              //graficas de tipos de equipos
              let array_datos_equipos=[];
              let nom_equipo=[];

              tipo_equipo.map(function(y){

                  array_datos_equipos.push(y.cantidad);
                  nom_equipo.push(y.nombre_equipo);

              });


               
               me.seriesEquipos=[{
                                      name: 'Equipos',
                                      data: array_datos_equipos
                                  }];



               me.optionsEquipos={
                           chart: {
                                      type: 'bar',
                                      height: 300
                                    },
                                     plotOptions: {
                                    bar: {
                                      borderRadius: 4,
                                      horizontal: true,
                                    }
                                  },
                                  dataLabels: {
                                    enabled: true
                                  },
                                  xaxis: {
                                          categories:nom_equipo
                                          }
               };





            me.listarTecnicos();

           


            

            me.ocultarLoading();
            me.dialogInicio=false;
          }).catch(function(error){
            console.log(error);
          });


      },

      listarTecnicos(){

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

          let me=this;

          this.ArrayTenicos=[];
          this.optionsEventosTecnico={};
          this.seriesEventosTecnico=[];
        

          axios.get('tecnicos', config
                    ).then(function(response){
                      console.log(response);
                        me.ArrayTenicos=response.data;


                          let nombre_tecnicos=[];
                          let valores_tecnicos=[];

                          console.log('datos en bitacoras:'+me.ArregloGlobalBitacoras.length);


                            me.ArrayTenicos.map(function(tec){

                                   tec.puntuacion=0;
                                 

                                  me.ArregloGlobalBitacoras.map(function(x){

                                  

                              
                                              let detalle_tecnicos=x.id_tecnicos;

                                              let cantidad_tenicos_trabajo=detalle_tecnicos.length;

                                              let puntuacion=1/cantidad_tenicos_trabajo;  //si hay dos tecnicos cuenta como mitad de trabajo 0.5
                                             

                                                detalle_tecnicos.map(function(y){

                                                  console.log('id_tecnico:'+tec._id+'-ref:'+y.ref_tecnico);

                                                // if(tec._id==y.ref_tecnico){
                                                  if(y.ref_tecnico.toString()===tec._id){
                                                    //if (tec._id === y.ref_tecnico) {
                                                  
                                                      console.log('antes'+ tec.puntuacion);
                                                      console.log('sumar:'+puntuacion);

                                                      tec.puntuacion=parseFloat(tec.puntuacion) + puntuacion;

                                                      console.log('luego'+ tec.puntuacion);

                                                      tec.puntuacion=parseFloat(tec.puntuacion).toFixed(2);
                                                      console.log('bingo mismo');
                                                  
                                                    
                                                  }

                                                });

                                           


                                      }); //fin de bitacoras

                                      console.log(tec.nombre_tecnico +':'+tec.puntuacion);

                                        nombre_tecnicos.push(tec.nombre_tecnico);
                                        valores_tecnicos.push(tec.puntuacion);



                                  });//fin de todas los tecnicos

                                 
                          






                               me.seriesEventosTecnico=[{
                                      name: 'Tecnico',
                                      data: valores_tecnicos
                                  }];



                                  
                me.optionsEventosTecnico={
                                     chart:{
                                        height: 300,
                                        type: 'bar',
                                      },
                                      plotOptions: {
                                        bar: {
                                          horizontal: false,
                                          columnWidth: '55%'
                                        },
                                      },
                                      
                                       title: {
                                        text: ''
                                      },
                                        subtitle: {
                                        text: '',
                                        align: 'center',
                                    },

                                      dataLabels: {
                                        enabled: true
                                      },

                                      stroke: {
                                        show: true,
                                        width: 2,
                                        colors: ['transparent']
                                      },
                                      xaxis: {
                                        categories:  nombre_tecnicos,
                                      },

                                      
                                     yaxis:[],
                                      fill: {
                                            opacity: 1
                                          }    


                                   };

                        
                         me.listarAccesorios();

                         me.ocultarLoading();
                    }).catch(function(error){
                      console.log(error);
                    });




      },

       listarAccesorios(){

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

          let me=this;


         this.ArrayAccesorios=[];
    

         this.data_excel=[];

             axios.get('accesorios', config
                    ).then(function(response){
                      console.log(response);
                      me.ArrayAccesorios=response.data;

                  
                        me.ProcesarBitacoras();
                        me.ocultarLoading();
                    }).catch(function(error){
                      console.log(error);
                    });


      },

      ProcesarBitacoras(){

          let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

          this.itemsMonetizar=[];
          this.data_excel=[];
          axios.post('bitacoras',
                               { 
                                'desde':new Date(this.fecha_inicio).toISOString().substr(0, 10),
                                'hasta':new Date(this.fecha_fin).toISOString().substr(0, 10) 
                              },
             config
          ).then(function(response){
            // console.log(response);
            let respuesta=response.data;



            respuesta.map(function(x){
              if((x.id_tipo_trabajo.monetizado==true)||(x.etiqueta=='Equipo_Manipulado')){

                if(x.etiqueta=='Equipo_Manipulado'){x.descripcion_trabajo='Equipo_Manipulado:'+x.descripcion_trabajo;}
                    me.itemsMonetizar.push(x);


                              let insert_excel=x;
                                  insert_excel.fecha= new Date(x.fecha).toISOString().substr(0, 10);
                                  insert_excel.cliente=x.id_bitacora_cliente.nombre_cliente;
                                  insert_excel.tipo_trabajo=x.id_tipo_trabajo.nombre_trabajo;


                                  if(x.id_tipo_equipo){
                                      insert_excel.tipo_equipo=x.id_tipo_equipo.nombre_equipo;
                                  }

                                  let texto_tecnicos='';

                                  x.id_tecnicos.map(function(y){
                                    texto_tecnicos=texto_tecnicos+''+ me.renderizarTecnico(y.ref_tecnico)+', ';
                                  });

                                  insert_excel.tecnicos=texto_tecnicos;

                                  let texto_accesorio='';

                                  let arr_acc=x.id_accesorios_instalados;

                              
                                  if((x.id_accesorios_instalados) &&( arr_acc.length >0)){

                                   arr_acc.map(function(z){
                                      texto_accesorio=texto_accesorio+''+ me.renderizarAccesorio(z.ref_accesorio)+',';
                                   });


                                 }
                                
                                  

                                  insert_excel.accesorios_instalados=texto_accesorio;

                               


                              me.data_excel.push(insert_excel);

                 }//si el trabao es monetizado


              let tipo_revision=false;
              let StringRevision='Revision';
              let nombre_trabajo=x.id_tipo_trabajo.nombre_trabajo;
              let posicionRev = nombre_trabajo.indexOf(StringRevision);
              if (posicionRev !== -1){
                  tipo_revision=true;
              }

              if((tipo_revision==true)&&(x.proveedor_simcard!='')){

                if(x.proveedor_simcard=='Tigo'){
                   me.CantidadtotalTigo++;   
                }else if(x.proveedor_simcard=='Claro'){
                   me.cantidadTotalClaro++;
                }
                 
               
              }



            });

            
          
         


            me.ocultarLoading();
            me.dialogInicio=false;
          }).catch(function(error){
            console.log(error);
          });


      },

      renderizarTecnico(_id){
          let texto='';

           this.ArrayTenicos.map(function(x){
                if(x._id==_id){ texto=x.nombre_tecnico;}
           });

           return(texto);

      },

       renderizarAccesorio(_id){

        let texto='';

           this.ArrayAccesorios.map(function(x){
                if(x._id==_id){ texto=x.nombre_accesorio;}
           });

           return(texto);


      },







      
    }

}
</script>

<style>

</style>