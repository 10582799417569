<template>
  <v-card>
    <v-card-title>
       <span>Accesorios</span>

        <v-divider  class="mx-2" inset vertical></v-divider>

         <v-flex  class="mx-2"  xs5 sm4 md5 lg4>
          <v-text-field  justify-center
                                v-model="search"
                                append-icon="search"
                                label="Busqueda"
                                single-line
                                hide-details
                              ></v-text-field> 

          </v-flex>


             <v-spacer></v-spacer>

           <v-btn color="primary"  @click="dialogAccesorios=true">
              <v-icon>add</v-icon>
            </v-btn>

        <v-btn color="success" class="mx-2" @click="listar()"><v-icon>autorenew</v-icon></v-btn>



    </v-card-title>

  <v-data-table
    :headers="headers"
    :items="AccesoriosArray"
    :search="search"
    sort-by="nombre_accesorio"
    class="elevation-1 mt-5 mx-1"
  >
  
       
       
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon  color="green" small class="mr-2" @click="editItem(item)">
       edit
      </v-icon>
      <v-icon  color="red" small @click="deleteItem(item)">
        delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">Reset</v-btn>
    </template>

      <template v-slot:[`item.activo`]="{ item }">
        <v-icon>
           {{ item.activo==true ? "done" : "label_off" }}
        </v-icon>
  </template>

  <template v-slot:[`item.creado`]="{ item }">
        <span>{{ getDate(item.creado) }}</span>
  </template>

   

 

  </v-data-table>
  
 
  <v-dialog v-model="dialogAccesorios" max-width="600px">
          <v-card>

              <v-toolbar color="red">
              <v-toolbar-title class="white--text">
                {{ formTitle }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogAccesorios=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                <v-row>

                  <v-col cols="12" sm="8" md="8">
                    <v-text-field v-model="editedItem.nombre_accesorio"  prepend-icon="person_outline" label="Nombre Accesorio"></v-text-field>
                  </v-col>
     
                   <v-col cols="12" sm="4" md="4">
                        <span>Estado</span><br>
                        <input type="radio" :value="1" v-model="editedItem.activo"> Activo
                        <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo
 
                   </v-col>

    
                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="red" dark @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>



    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">deseas deshabilitar Accesorio?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

</v-card>
</template>


<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";

  export default {
    data: () => ({
      AccesoriosArray:[],
    
       search:'',
      dialogAccesorios: false,
      dialogDelete: false,
      headers: [
     
        { text: 'Nombre Accesorio', value: 'nombre_accesorio' },
        { text: 'Activo', value: 'activo' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      editedIndex: -1,
      editedItem: {
        nombre_equipo: '',
        activo: 1,
        creado:new Date()
      },
      defaultItem: {
        nombre_equipo: '',
        activo: 1,
        creado:new Date()
      },


      errorMessages: '',
      valid: true,
      rules: {
          min3Chars: (value) => value.length >= 3 || "Min. 3 characters",
          required: (value) => !!value || "Campo Requerido.",
          email: (value) => {
                              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                              return pattern.test(value) || "Invalid e-mail.";
                            },
          }


    }),

    computed: {

       ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Accesorio' : 'Edicion Accesorio'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.listar();
    },

    methods: {

       ...mapMutations(['mostrarLoading','ocultarLoading']),




      listar () {

          let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'accediendo a datos'});
          let me=this;

          axios.get('accesorios', config
          ).then(function(response){
              console.log(response);
              me.AccesoriosArray=response.data;
              me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

      },



      editItem (item) {

            this.editedIndex = this.AccesoriosArray.indexOf(item)
            this.editedItem = Object.assign({}, item);
            this.dialogAccesorios=true;
      },



      deleteItem (item) {
        this.editedIndex = this.AccesoriosArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true;
      },

      deleteItemConfirm () {


            let id=this.editedItem._id;
            let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'accediendo a datos'});

             axios.put(`accesorios/${id}`, 
                                {'activo':0},
                                config
                              ).then(response=>{
                               
                                 this.closeDelete();
                                 this.ocultarLoading();
                                 console.log(response);
                                 this.listar();

                              }).catch(e=>{
                                    console.log(e)
                              });

      

         


      },

      close () {
        this.dialogAccesorios = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
       
              let id=this.editedItem._id;
               this.mostrarLoading({titulo:'accediendo a datos'});
               let config={headers:{token:this.token}};

             axios.put(`accesorios/${id}`, 
                                { 
                                  'nombre_accesorio':this.editedItem.nombre_accesorio,
                                  'activo':this.editedItem.activo
                                },
                                config
                              ).then(response=>{
                               
                                this.close();
                                this.ocultarLoading();
                                console.log(response);
                                this.listar();

                              }).catch(e=>{
                                    console.log(e)
                              });



        } else {
            
         //Guardamos datos de conductores

            let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'accediendo a datos'});

            axios.post('nuevo-accesorio',
                    { 
                     
                        'nombre_accesorio':this.editedItem.nombre_accesorio,
                        'activo':this.editedItem.activo
                   

                       },
              config
          ).then(response=>{


         
          
            this.close();
            console.log(response);
            this.listar();
            this.ocultarLoading();

          }).catch(e=>{
                console.log(e)
          });



        }
        
      },



      getDate(evaluar) {
          let date = new Date(evaluar),
          year = date.getFullYear(),
          month = (date.getMonth() + 1).toString(),
          formatedMonth = (month.length === 1) ? ("0" + month) : month,
          day = date.getDate().toString(),
          formatedDay = (day.length === 1) ? ("0" + day) : day;
         //  hour = date.getHours().toString(),
         // formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
         // minute = date.getMinutes().toString(),
         // formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
         // second = date.getSeconds().toString(),
         //  formatedSecond = (second.length === 1) ? ("0" + second) : second;
        return formatedDay + "-" + formatedMonth + "-" + year;
  },



    },
  }
</script>